<template>
  <div class="main">
    <!-- 懸浮的div，包著側邊選單和回到頂端按鈕 -->
    <div style="
        float: right;
        z-index: 999;
        position: fixed;
        right: 1vmin;
        padding-top: 15vmin;
      ">
      <!-- 側邊懸浮選單 -->
      <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeInUp"
        leave-active-class="animate__animated animate__fadeOutDown"
        mode="in-out"
      >
        <el-row
          :span="10"
          style="margin-right: 10px"
          v-show="MenuT"
        >
          <el-col>
            <el-menu
              class="el-menu-vertical-demo"
              text-color="#9E9E9E"
              active-text-color="#409EFF"
              :default-active="MenuIndex"
            >
              <el-menu-item
                index="1"
                @click="Scroll('Info')"
                class="el-top-item"
              >
                <template #title><i class="el-icon-s-flag"></i><span>關於競賽</span></template>
              </el-menu-item>
              <el-menu-item
                index="2"
                @click="Scroll('News')"
              >
                <template #title><i class="el-icon-s-platform"></i><span>最新消息</span></template>
              </el-menu-item>
              <el-menu-item
                index="3"
                @click="Scroll('Schedule')"
              >
                <template #title><i class="el-icon-time"></i><span>競賽時程</span></template>
              </el-menu-item>
              <el-menu-item
                index="4"
                @click="Scroll('Rule')"
              >
                <template #title><i class="el-icon-s-check"></i><span>競賽辦法</span></template>
              </el-menu-item>
              <el-menu-item
                index="5"
                @click="Scroll('List')"
              >
                <template #title><i class="el-icon-tickets"></i><span>名單公布</span></template>
              </el-menu-item>

              <el-menu-item
                index="6"
                @click="Scroll('Works')"
              >
                <template #title><i class="el-icon-s-open"></i><span>歷屆作品</span></template>
              </el-menu-item>

              <el-menu-item
                index="7"
                @click="Scroll('Activities')"
              >
                <template #title><i class="el-icon-camera"></i><span>活動花絮</span></template>
              </el-menu-item>

              <el-menu-item
                index="8"
                @click="Scroll('Faq')"
              >
                <template #title><i class="el-icon-question"></i><span>FAQ</span></template>
              </el-menu-item>

              <el-menu-item
                index="9"
                class="el-bottom-item"
                onclick="window.open('https://ic.nkust.edu.tw/')"
              >
                <template #title><i class="el-icon-school"></i><span>智慧商務系</span></template>
              </el-menu-item>
              <!--  -->
            </el-menu>
          </el-col>
        </el-row>
      </transition>
      <!-- 回到頂端按鈕 -->
      <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeInUp"
        leave-active-class="animate__animated animate__fadeOutDown"
      >
        <el-button
          type="primary"
          icon="el-icon-s-home"
          style="
            box-shadow: 2px 2px 5px rgb(50, 50, 50);
            float: right;
            z-index: 999;
            position: fixed;
            right: 2vmin;
            bottom: 2vmin;
            width: 145px;
          "
          @click.native="Scroll('Index')"
          v-show="HomeBtn"
        >回到頂端</el-button>
      </transition>
    </div>

    <section
      class="section1"
      id="Index"
    >
      <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeInRight"
        leave-active-class="animate__animated animate__fadeOutLeft"
        mode="out-in"
      >
        <div
          class="main_title_container"
          v-show="S1t"
        >
          <div class="main_title">第四屆高科盃<br />全國商業智慧競賽</div>
          <div style="font-size: 3vmin">
            徵稿至 2023-11-01 (三) 中午12:00<br />
            總獎金 63,000 元整
          </div>
          <el-button
            disabled
            plain
            round
            style="
              margin-top: 10px;
              width: 22vmin;
              font-weight: 900;
              font-size: 20px;
              height: 47px;
              text-align:
              font-family: Microsoft JhengHei
            "
            class="
              animate__animated animate__pulse animate__infinite animate__slower
            "
            onclick="window.open('https://forms.gle/feRwwgXHpzypNivw6')"
            target="_blank"
          >報名已截止</el-button>
        </div>
      </transition>
      <div
        style="
          color: white;
          font-size: 2vmin;
          position: absolute;
          bottom: 50px;
          width: 100%;
          text-align: center;
          font-weight: bold;
          filter: drop-shadow(3px 3px 2px #000000);
        "
        class="float"
      >
        向下捲動以查看更多<br />
        <i
          style="font-size: 7vmin"
          class="el-icon-bottom"
        ></i>
      </div>
    </section>
    <section
      class="section2"
      id="Info"
    >
      <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeInRight"
        leave-active-class="animate__animated animate__fadeOutLeft"
        mode="out-in"
      >
        <div
          class="main_title_container"
          v-show="S2t"
        >
          <div class="main_title">關於競賽</div>
          <div style="font-size: 2vmin">
            為引發高中職及大學部學生對於「智慧商務」之創意構想，培養跨領域之專業人才，鼓勵學生積極嘗試與發想，提高創新思考能力，並提昇知識整合、實作及人際溝通合作之能力。順應新課綱強調專題實作與實習科目學習成果，鼓勵同學將上述成果延伸本競賽，展現同學在課綱核心素養的具體實現，與活用專業知識與技能的能力，並增加未來在職場創就業之競爭力。國立高雄科技大學商業智慧學院及智慧商務系舉辦「第四屆高科盃全國商業智慧競賽」，期盼能激發學生創意興趣及增加未來在職場創就業之競爭力，並提供全國各校學生相互交流的機會。
          </div>
          <el-divider></el-divider>
          <el-row :gutter="24">
            <el-col :span="8">
              <div class="grid-content bg-purple">主辦單位</div>
            </el-col>
            <el-col :span="16">
              <div class="grid-content bg-purple">
                <a href="https://ic.nkust.edu.tw/" target="_blank" style="text-decoration:none;color:#ffffff">國立高雄科技大學商業智慧學院暨智慧商務系</a>
              </div>
            </el-col>
          </el-row>
          <!-- <el-row :gutter="24">
            <el-col :span="8">
              <div class="grid-content bg-purple">協辦單位</div>
            </el-col>
            <el-col :span="16">
              <div class="grid-content bg-purple">
                <ul style="padding: 0px; margin: 0px">
                  <li></li>
                </ul>
              </div>
            </el-col>
          </el-row> -->
        </div>
      </transition>
    </section>
    <section
      class="section3"
      id="News"
    >
      <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeInRight"
        leave-active-class="animate__animated animate__fadeOutLeft"
        mode="out-in"
      >
        <div
          class="main_title_container"
          v-show="S3t"
        >
          <div class="main_title">最新消息</div>
          <el-divider></el-divider>
          <el-collapse
            accordion
            style="border: 0px; padding-bottom: 0px; border-radius: 5px"
          >
          <el-collapse-item>
            <template slot="title"><i class="header-icon el-icon-star-on"></i>
              第四屆媒體報導回顧！
              </template>
              <div>
                高科盃全國商業智慧競賽落幕 智慧商務應用多元精采<br/>
                &emsp;👉<el-link
                  type="primary"
                  href="https://www.nkust.edu.tw/p/406-1000-75401,r1363.php?Lang=zh-tw"
                  target="_blank"
                  :underline="false"
                  style="vertical-align: baseline;font-size:13px"
                >新聞連結</el-link>
                <br/>
                高科盃全國商業智慧競賽 參賽選手展現智慧應用無限創意<br/>
                &emsp;👉<el-link
                  type="primary"
                  href="https://reurl.cc/54kYNM"
                  target="_blank"
                  :underline="false"
                  style="vertical-align: baseline;font-size:13px"
                >新聞連結</el-link>
                <br/>
                漾新聞 | 高科盃全國商業智慧競賽 北商大、馬公高中、海青工商分獲各組冠軍<br/>
                &emsp;👉<el-link
                  type="primary"
                  href="https://news.owlting.com/articles/551910"
                  target="_blank"
                  :underline="false"
                  style="vertical-align: baseline;font-size:13px"
                >新聞連結</el-link>
                <br/>
                高科盃全國商業智慧競賽落幕 智慧商務應用多元精采<br/>
                &emsp;👉<el-link
                  type="primary"
                  href="https://www.tssdnews.com.tw/?FID=67&CID=713328#google_vignette"
                  target="_blank"
                  :underline="false"
                  style="vertical-align: baseline;font-size:13px"
                >新聞連結</el-link>
                <br/>
                <img :src="require('../assets/gen4.jpg')" style="max-width: 80%; height: auto;" loading="lazy" alt="" />
              </div>
          </el-collapse-item>
          <el-collapse-item>
            <template slot="title"><i class="header-icon el-icon-star-on"></i>
                第四屆參賽證明下載開放公告！
              </template>
              <div>
                「第四屆高科盃全國商業智慧競賽」參賽證明，開放網路下載囉！<br/>
                &emsp;非常感謝大家的參與！<br/>
                &emsp;👉<el-link
                  type="primary"
                  href="https://drive.google.com/drive/folders/1q8RtAzUJh3QoREys1ONJKmL9VI_daXLm"
                  target="_blank"
                  :underline="false"
                  style="vertical-align: baseline;font-size:13px"
                >參賽證明下載</el-link>
                <br/>
              </div>
          </el-collapse-item>
          <el-collapse-item>
            <template slot="title"><i class="header-icon el-icon-star-on"></i>
                第四屆決賽得獎名單公布！
              </template>
              <div>
                第四屆決賽最終得獎名單已經公布，請移至<el-link
                  type="primary"
                  href="/#List"
                  target="_blank"
                  :underline="false"
                  style="vertical-align: baseline;font-size:13px"
                >名單公布</el-link>查看。
                <br/>
                非常感謝大家的參與！
              </div>
          </el-collapse-item>
          <el-collapse-item>
            <template slot="title"><i class="header-icon el-icon-star-on"></i>
                第四屆決賽頒獎名單公布！
              </template>
              <div>
                第四屆決賽頒獎名單已經公布，請移至<el-link
                  type="primary"
                  href="/#List"
                  target="_blank"
                  :underline="false"
                  style="vertical-align: baseline;font-size:13px"
                >名單公布</el-link>查看。
                <br/>
              </div>
          </el-collapse-item>
          <el-collapse-item>
              <template slot="title"><i class="header-icon el-icon-star-on"></i>
                第四屆入圍決賽名單公布！
              </template>
              <div>
                入圍決賽名單已經公布，請移至<el-link
                  type="primary"
                  href="/#List"
                  target="_blank"
                  :underline="false"
                  style="vertical-align: baseline;font-size:13px"
                >名單公布</el-link>查看。
              </div>
            </el-collapse-item>
            <el-collapse-item>
              <template slot="title"><i class="header-icon el-icon-info"></i>
                初賽徵稿至 2023-11-01 (三) 中午12:00止
              </template>
              <div>
                高中職概念組請繳交提案說明書 <el-link
                  type="primary"
                  href="./attach_1.pdf"
                  target="_blank"
                >(附件一)</el-link><br />
                高中職實作組請繳交作品說明書 <el-link
                  type="primary"
                  href="./attach_2.pdf"
                  target="_blank"
                >(附件二)</el-link><br />
                大學概念組請繳交提案說明書 <el-link
                  type="primary"
                  href="./attach_3.pdf"
                  target="_blank"
                >(附件三)</el-link>
                <el-divider></el-divider>
                參賽作品只要有參加過國內外相關競賽者，欲以相同或相近內容參與本競賽，無論是否獲獎，皆須填寫延續性作品說明書，連同初賽作品、學生證明、著作財產權授權同意書、法定代理人同意書、請上傳至google表單，不須提供紙本。<br />
                <el-link
                  type="primary"
                  href="https://forms.gle/coDqU3Nu92N4dvmCA"
                  target="_blank"
                >初賽繳件Google表單</el-link><br />
                <el-link
                  type="primary"
                  href="https://reurl.cc/WEzYee"
                  target="_blank"
                >表單下載</el-link>
                <br /><b>繳交資料請確認清楚，逾時不侯，缺件者放棄競賽資格。</b>
                <el-divider></el-divider>
                評審委員在每組中評選10隊晉級決賽(視狀況酌予增減)
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </transition>
    </section>
    <section
      class="section4"
      id="Schedule"
    >
      <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeInRight"
        leave-active-class="animate__animated animate__fadeOutLeft"
        mode="out-in"
      >
        <div
          class="main_title_container"
          v-show="S4t"
        >
          <div class="main_title">競賽時程</div>
          <el-divider></el-divider>
          <div style="padding-top: 3vmin;">
            <el-timeline style="padding-left: 15vmin;color:#ffffff">
              <el-timeline-item
                v-for="(activity, index) in this.$Global_data.Activities"
                :key="index"
              >
                <div style="color: #ffffff; font-size: 3vmin">
                  {{ activity.content }}
                </div>
                <div style="color: #ffffff; font-size: 1.5vmin">
                  {{ activity.timestamp }}
                </div>
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
      </transition>
    </section>
    <section
      class="section5"
      id="Rule"
      style="overflow-y: scroll"
    >
      <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeInRight"
        leave-active-class="animate__animated animate__fadeOutLeft"
        mode="out-in"
      >
        <div
          class="main_title_container"
          v-show="S5t"
          style="width: 70vw"
        >
          <div class="main_title">競賽辦法</div>
          <el-divider></el-divider>
          <el-row
            style="overflow-y: scroll; overflow-x: hidden"
            :gutter="20"
          >
            <el-col :span="6">
              <el-container class="RuleTitleContainer">
                <div
                  v-for="(el, index) in this.Rules"
                  :key="index"
                  class="RuleTitle"
                  :class="{ RuleIndexActive: RuleIndex === index }"
                  @click="RuleIndex = index"
                >
                  {{ el }}
                </div>
              </el-container>
            </el-col>
            <el-col :span="18">
              <el-container class="RuleContentContainer">
                <transition
                  name="el-fade-in"
                  mode="out-in"
                >
                  <div
                    key="0"
                    v-if="RuleIndex === 0"
                  >
                    <div class="RuleContentTitle">參賽對象</div>
                    <el-divider style="margin-top: 1vmin; margin-bottom: 1vmin"></el-divider>
                    <div class="RuleContent">
                      全國高中職及大學在學生，不拘科皆可參加！
                    </div>
                  </div>
                  <div
                    key="1"
                    v-if="RuleIndex === 1"
                  >
                    <div class="RuleContentTitle">參賽規定</div>
                    <el-divider style="margin-top: 1vmin; margin-bottom: 1vmin"></el-divider>
                    <div class="RuleContent">
                      <ol>
                        <li
                          v-for="(el, index) in this.$Global_data.CptRegulation"
                          :key="index"
                        >
                          {{ el.data }}
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div
                    key="2"
                    v-else-if="RuleIndex === 2"
                  >
                    <div class="RuleContentTitle">組別說明</div>
                    <el-divider style="margin-top: 1vmin; margin-bottom: 1vmin"></el-divider>
                    <div class="RuleContent">
                      <div>
                        <span>高中職概念組</span>
                        <br />
                        <span>提供創意的「商業智慧」提案，可以解決或改善商務問題，優化商業行為、商業模式、商業品質上的創意產品，均適合本項競賽。參賽團隊請依照自行設定之主題，進行商業智慧的應用發想，提出產品提案。</span>
                      </div>
                      <el-divider></el-divider>
                      <div>
                        高中職實作組
                        <br />
                        實作「商業智慧」作品，包含物聯網、機器人、大數據分析及其他商業資訊技術領域，凡可解決或改善商務問題，優化商業行為之作品，皆符合本競賽。參賽團隊請依照自行設定之主題，進行商業智慧的應用實作。
                      </div>
                      <el-divider></el-divider>
                      <div>
                        大學概念組
                        <br />
                        以智慧科技，創新服務流程，創新經營模式，創新產品應用於新世代之下的數位轉型，智慧商務，財務管理科技，金融科技，品牌行銷，休閒光觀事業，文化創意產業或是新式商業模式與元宇宙等議題皆可依主題進行應用發想並且提出具備創意與可行的企劃書或構想書。
                      </div>
                    </div>
                  </div>
                  <div
                    key="3"
                    v-else-if="RuleIndex === 3"
                  >
                    <div class="RuleContentTitle">【初賽】交件說明</div>
                    <el-divider style="margin-top: 1vmin; margin-bottom: 1vmin"></el-divider>
                    <div class="RuleContent">
                      高中職概念組請繳交提案說明書 <el-link
                        type="primary"
                        href="./attach_1.pdf"
                        target="_blank"
                      >(附件一)</el-link><br />
                      高中職實作組請繳交作品說明書 <el-link
                        type="primary"
                        href="./attach_2.pdf"
                        target="_blank"
                      >(附件二)</el-link><br />
                      大學概念組請繳交提案說明書 <el-link
                        type="primary"
                        href="./attach_3.pdf"
                        target="_blank"
                      >(附件三)</el-link>
                      <el-divider></el-divider>
                      參賽作品只要有參加過國內外相關競賽者，欲以相同或相近內容參與本競賽，無論是否獲獎，皆須填寫延續性作品說明書，連同初賽作品、學生證明、著作財產權授權同意書、法定代理人同意書、請上傳至google表單，不須提供紙本。<br />
                      <el-link
                        type="primary"
                        href="https://forms.gle/feRwwgXHpzypNivw6"
                        target="_blank"
                      >初賽繳件Google表單</el-link><br />
                      <el-link
                        type="primary"
                        href="https://reurl.cc/WEzYee"
                        target="_blank"
                      >表單下載</el-link>
                      <br /><b>繳交資料請確認清楚，逾時不侯，缺件者放棄競賽資格。</b>
                      <el-divider></el-divider>
                      評審委員在每組中評選10隊晉級決賽(視狀況酌予增減)
                    </div>
                  </div>
                  <div
                    key="4"
                    v-else-if="RuleIndex === 4"
                  >
                    <div class="RuleContentTitle">【初賽】評分項目</div>
                    <el-divider style="margin-top: 1vmin; margin-bottom: 1vmin"></el-divider>
                    <div class="RuleContent">
                      <el-row
                        style="overflow-y: scroll; overflow-x: hidden"
                        :gutter="60"
                      >
                        <el-col :span="12">
                          <div style="
                              text-align: center;
                              font-size: 3vmin;
                              font-weight: 900;
                            ">
                            高中職概念組/大學概念組
                          </div>
                          <Pie-chart
                            :chart-data="this.$Global_data.DataC"
                            :options="options"
                          ></Pie-chart>
                        </el-col>
                        <el-col :span="12">
                          <div style="
                              text-align: center;
                              font-size: 3vmin;
                              font-weight: 900;
                            ">
                            高中職實作組
                          </div>
                          <Pie-chart
                            :chart-data="this.$Global_data.DataI"
                            :options="options"
                          ></Pie-chart>
                        </el-col>
                      </el-row>
                    </div>
                  </div>
                  <div
                    key="5"
                    v-else-if="RuleIndex === 5"
                  >
                    <div class="RuleContentTitle">【決賽】交件說明</div>
                    <el-divider style="margin-top: 1vmin; margin-bottom: 1vmin"></el-divider>
                    <div class="RuleContent">
                      本次決賽以線上形式進行，最終得獎隊伍另安排實體頒獎典禮。<br/>
                      <b>⚠️得獎隊伍需至少一人參加典禮，否則將取消該獎項。⚠️</b><br />
                      高中職概念組、高中職實作組及大學概念組請繳交簡報 <el-link
                        type="primary"
                        href="./attach_4.pdf"
                        target="_blank"
                      >(附件四)。</el-link><br />
                      高中職實作組簡報內容需含作品展示(亦可以影片方式或線上直接demo)。<br />
                      線上報告禁止使用預錄方式報告，違者將予扣分。<br />
                    </div>
                  </div>
                  <div
                    key="6"
                    v-else-if="RuleIndex === 6"
                  >
                    <div class="RuleContentTitle">【決賽】競賽規定</div>
                    <el-divider style="margin-top: 1vmin; margin-bottom: 1vmin"></el-divider>
                    <div class="RuleContent">
                      高中職概念組<br />
                      評審針對參賽者簡報內容及表現進行評分。<br /><b>每隊8分鐘，問答時間5分鐘(統問統答)，簡報統一由大會電腦播放</b>。
                      <el-divider></el-divider>
                      高中職實作組<br />
                      各隊展示、解說作品，評審針對參賽者簡報內容及表現進行評分。<br /><b>每隊8分鐘，問答時間5分鐘(統問統答)，簡報統一由大會電腦播放</b>。
                      <el-divider></el-divider>
                      大學概念組<br />
                      評審針對參賽者簡報內容及表現進行評分。<br /><b>每隊8分鐘，問答時間5分鐘(統問統答)，簡報統一由大會電腦播放</b>。
                    </div>
                  </div>
                  <div
                    key="7"
                    v-else-if="RuleIndex === 7"
                  >
                    <div class="RuleContentTitle">【決賽】評分項目</div>
                    <el-divider style="margin-top: 1vmin; margin-bottom: 1vmin"></el-divider>
                    <div class="RuleContent">
                      <el-row
                        style="overflow-y: scroll; overflow-x: hidden"
                        :gutter="60"
                      >
                        <el-col :span="12">
                          <div style="
                              text-align: center;
                              font-size: 3vmin;
                              font-weight: 900;
                            ">
                            高中職概念組/大學概念組
                          </div>
                          <Pie-chart
                            :chart-data="this.$Global_data.DataC2"
                            :options="options"
                          ></Pie-chart>
                        </el-col>
                        <el-col :span="12">
                          <div style="
                              text-align: center;
                              font-size: 3vmin;
                              font-weight: 900;
                            ">
                            高中職實作組
                          </div>
                          <Pie-chart
                            :chart-data="this.$Global_data.DataI2"
                            :options="options"
                          ></Pie-chart>
                        </el-col>
                      </el-row>
                    </div>
                  </div>
                  <div
                    key="8"
                    v-else-if="RuleIndex === 8"
                  >
                    <div class="RuleContentTitle">獎勵方法</div>
                    <el-divider style="margin-top: 1vmin; margin-bottom: 1vmin"></el-divider>
                    <div class="RuleContent">
                      <ol>
                        <li
                          v-for="(el, index) in this.$Global_data.AwardInfo"
                          :key="index"
                        >
                          {{ el.data }}
                        </li>
                      </ol>
                      <el-divider></el-divider>
                      <ul>
                        <li
                          v-for="(el, index) in this.$Global_data.IdeaAward"
                          :key="index"
                        >
                          {{ el.data }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    key="9"
                    v-else-if="RuleIndex === 9"
                  >
                    <div class="RuleContentTitle">注意事項</div>
                    <el-divider style="margin-top: 1vmin; margin-bottom: 1vmin"></el-divider>
                    <div class="RuleContent">
                      <ol>
                        <li
                          v-for="(el, index) in this.$Global_data.CptCaution"
                          :key="index"
                        >
                          {{ el.data }}
                        </li>
                      </ol>
                    </div>
                  </div>
                </transition>
              </el-container>
            </el-col>
          </el-row>
        </div>
      </transition>
    </section>
    <section
      class="section6"
      id="List"
    >
      <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeInRight"
        leave-active-class="animate__animated animate__fadeOutLeft"
        mode="out-in"
      >
        <div
          class="main_title_container"
          v-show="S6t"
          style="width: 60vw"
        >
          <div class="main_title">名單公布</div>
          <el-divider style="background-color: #ffffff;"></el-divider>
          <el-container class="RuleContentContainer">
            <h2>第四屆決賽得獎名單</h2>
            <el-collapse accordion>
              <el-collapse-item
              title="高中職概念組"
              name="1"
              >
                <el-row style="width:100%">
                  <el-col :span="12">
                    <ul class="final-li">
                      <li>&emsp;第一名
                        <el-divider direction="vertical" />澎湖人都騎海豚🐬
                      </li>
                      <li>&emsp;第二名
                        <el-divider direction="vertical" />高科玉米
                      </li>
                      <li>&emsp;第三名
                        <el-divider direction="vertical" />這感覺很隊
                      </li>
                      <li>&emsp;&emsp;佳作
                        <el-divider direction="vertical" />爺們要戰鬥
                      </li>
                      <li>&emsp;&emsp;佳作
                        <el-divider direction="vertical" />尊嘟假嘟
                      </li>
                    </ul>
                  </el-col>
                  <el-col :span="12">
                    <ul class="final-li">
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />閃亮芭比組
                      </li>
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />均安有一個海貓夢
                      </li>
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />紅豆糾察隊
                      </li>
                      <li>入圍獎
                        <el-divider direction="vertical" />卡布呱啦
                      </li>
                      <li>入圍獎
                        <el-divider direction="vertical" />省水專家
                      </li>
                    </ul>
                  </el-col>
                </el-row>
              </el-collapse-item>
              <el-collapse-item
              title="高中職實作組"
              name="2"
              >
                <el-row style="width:100%">
                  <el-col :span="12">
                    <ul class="final-li">
                      <li>&emsp;第一名
                        <el-divider direction="vertical" />從缺
                      </li>
                      <li>&emsp;第二名
                        <el-divider direction="vertical" />2牽五的Smart插座尖嘴千～~
                      </li>
                      <li>&emsp;第三名
                        <el-divider direction="vertical" />黑白配 男生女生配
                      </li>
                      <li>&emsp;&emsp;佳作
                        <el-divider direction="vertical" />酒醉雄心
                      </li>
                    </ul>
                  </el-col>
                  <el-col :span="12">
                    <ul class="final-li">
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />七杯大冰拿
                      </li>
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />來自高雄的電墊
                      </li>
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />垃圾清理大師
                      </li>
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />吃我壽司
                      </li>
                    </ul>
                  </el-col>
                </el-row>
              </el-collapse-item>
              <el-collapse-item
              title="大學概念組"
              name="3"
              >
                <el-row style="width:100%">
                  <el-col :span="12">
                    <ul class="final-li">
                      <li>&emsp;第一名
                        <el-divider direction="vertical" />懶人闖江湖666
                      </li>
                      <li>&emsp;第二名
                        <el-divider direction="vertical" />橙柚會成功
                      </li>
                      <li>&emsp;第三名
                        <el-divider direction="vertical" />確食
                      </li>
                      <li>&emsp;&emsp;佳作
                        <el-divider direction="vertical" />DUCK不必
                      </li>
                      <li>&emsp;&emsp;佳作
                        <el-divider direction="vertical" />三寶剋星
                      </li>
                    </ul>
                  </el-col>
                  <el-col :span="12">
                    <ul class="final-li">
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />深海的大鳳梨裡
                      </li>
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />海豚大車隊
                      </li>
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />安全第一
                      </li>
                      <li>入圍獎
                        <el-divider direction="vertical" />SmartWC隊
                      </li>
                      <li>入圍獎
                        <el-divider direction="vertical" />三零四二二
                      </li>
                    </ul>
                  </el-col>
                </el-row>
              </el-collapse-item>
            </el-collapse>
            <h2>第四屆決賽頒獎名單</h2>
            <el-collapse accordion>
              <el-collapse-item
                title="高中職概念組"
                name="1"
              >
                <el-row style="width:85%">
                  <el-col :span="12">
                    <ul>
                      <li>均安有一個海貓夢</li>
                      <li>澎湖人都騎海豚🐬</li>
                      <li>高科玉米</li>
                      <li>這感覺很隊</li>
                    </ul>
                  </el-col>
                  <el-col :span="12">
                    <ul>
                      <li>尊嘟假嘟</li>
                      <li>爺們要戰鬥</li>
                      <li>閃亮芭比組</li>
                      <li>紅豆糾察隊</li>
                    </ul>
                  </el-col>
                </el-row>
              </el-collapse-item>
              <el-collapse-item
                title="高中職實作組"
                name="2"
              >
                <el-row style="width:85%">
                  <el-col :span="12">
                    <ul>
                      <li>從缺</li>
                      <li>2牽五的Smart插座尖嘴千～～</li>
                      <li>酒醉雄心</li>
                      <li>來自高雄的電墊</li>
                    </ul>
                  </el-col>
                  <el-col :span="12">
                    <ul>
                      <li>七杯大冰拿</li>
                      <li>垃圾清理大師</li>
                      <li>黑白配 男生女生配</li>
                      <li>吃我壽司</li>
                    </ul>
                  </el-col>
                </el-row>
              </el-collapse-item>
              <el-collapse-item
                title="大學概念組"
                name="3"
              >
                <el-row style="width:85%">
                  <el-col :span="12">
                    <ul>
                      <li>DUCK不必</li>
                      <li>確食</li>
                      <li>安全第一</li>
                      <li>懶人闖江湖666</li>
                    </ul>
                  </el-col>
                  <el-col :span="12">
                    <ul>
                      <li>橙柚會成功</li>
                      <li>海豚大車隊</li>
                      <li>深海的大鳳梨裡</li>
                      <li>三寶剋星</li>
                    </ul>
                  </el-col>
                </el-row>
              </el-collapse-item>
            </el-collapse>
            <h2>第四屆入圍決賽名單</h2>
            <el-collapse accordion>
              <el-collapse-item
                title="高中職概念組"
                name="1"
              >
                <el-row style="width:85%">
                  <el-col :span="12">
                    <ul>
                      <li>均安有一個海貓夢</li>
                      <li>澎湖人都騎海豚🐬</li>
                      <li>高科玉米</li>
                      <li>這感覺很隊</li>
                      <li>尊嘟假嘟</li>
                    </ul>
                  </el-col>
                  <el-col :span="12">
                    <ul>
                      <li>爺們要戰鬥</li>
                      <li>閃亮芭比組</li>
                      <li>省水專家</li>
                      <li>紅豆糾察隊</li>
                      <li>卡布呱啦</li>
                    </ul>
                  </el-col>
                </el-row>
              </el-collapse-item>
              <el-collapse-item
                title="高中職實作組"
                name="2"
              >
                <el-row style="width:85%">
                  <el-col :span="12">
                    <ul>
                      <li>從缺</li>
                      <li>2牽五的Smart插座尖嘴千～～</li>
                      <li>酒醉雄心</li>
                      <li>來自高雄的電墊</li>
                    </ul>
                  </el-col>
                  <el-col :span="12">
                    <ul>
                      <li>七杯大冰拿</li>
                      <li>垃圾清理大師</li>
                      <li>黑白配 男生女生配</li>
                      <li>吃我壽司</li>
                    </ul>
                  </el-col>
                </el-row>
              </el-collapse-item>
              <el-collapse-item
                title="大學概念組"
                name="3"
              >
                <el-row style="width:85%">
                  <el-col :span="12">
                    <ul>
                      <li>DUCK不必</li>
                      <li>確食</li>
                      <li>安全第一</li>
                      <li>懶人闖江湖666</li>
                      <li>橙柚會成功</li>
                    </ul>
                  </el-col>
                  <el-col :span="12">
                    <ul>
                      <li>SmartWC隊</li>
                      <li>海豚大車隊</li>
                      <li>三零四二二</li>
                      <li>深海的大鳳梨裡</li>
                      <li>三寶剋星</li>
                    </ul>
                  </el-col>
                </el-row>
              </el-collapse-item>
            </el-collapse>
          </el-container>
        </div>
      </transition>
    </section>
    <section
      class="section7"
      id="Works"
    >
      <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeInRight"
        leave-active-class="animate__animated animate__fadeOutLeft"
        mode="out-in"
      >
        <div
          class="main_title_container"
          v-show="S7t"
          style="width: 60vw"
        >
          <div class="main_title">歷屆得獎作品</div>
          <el-divider style="background-color: #ffffff"></el-divider>
          <el-container class="RuleContentContainer">
            <h2 class="el-icon-star-on">2022年第三屆得獎名單</h2>
            <el-collapse accordion>
              <el-collapse-item
              title="高中職實作組"
              name="1"
              >
                <el-row style="width:100%">
                  <el-col :span="12">
                    <ul class="final-li">
                      <li>&emsp;第一名
                        <el-divider direction="vertical" />水下機器人
                      </li>
                      <li>&emsp;第二名
                        <el-divider direction="vertical" />打火英雄
                      </li>
                      <li>&emsp;第三名
                        <el-divider direction="vertical" />不再怕受傷
                      </li>
                      <li>&emsp;&emsp;佳作
                        <el-divider direction="vertical" />IOT智慧水族系統
                      </li>
                      <li>&emsp;&emsp;佳作
                        <el-divider direction="vertical" />太陽板板
                      </li>
                    </ul>
                  </el-col>
                  <el-col :span="12">
                    <ul class="final-li">
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />找藥不會錯
                      </li>
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />AIoT壓縮垃圾及自動分類器之開發及應用
                      </li>
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />School 購 easy
                      </li>
                      <li>入圍獎
                        <el-divider direction="vertical" />跟著太陽一起起床
                      </li>
                      <li>入圍獎
                        <el-divider direction="vertical" />找樂子
                      </li>
                    </ul>
                  </el-col>
                </el-row>
              </el-collapse-item>
              <el-collapse-item
              title="高中職概念組"
              name="2"
              >
                <el-row style="width:100%">
                  <el-col :span="12">
                    <ul class="final-li">
                      <li>&emsp;第一名
                        <el-divider direction="vertical" />不同凡響
                      </li>
                      <li>&emsp;第二名
                        <el-divider direction="vertical" />尋「棗」幸福
                      </li>
                      <li>&emsp;第三名
                        <el-divider direction="vertical" />對對對對隊
                      </li>
                      <li>&emsp;&emsp;佳作
                        <el-divider direction="vertical" />地球是我家，保護靠大家
                      </li>
                      <li>&emsp;&emsp;佳作
                        <el-divider direction="vertical" />深藏BLUE
                      </li>
                    </ul>
                  </el-col>
                  <el-col :span="12">
                    <ul class="final-li">
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />金榜題米 步步糕升
                      </li>
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />五告喝邱
                      </li>
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />玖肆三
                      </li>
                      <li>入圍獎
                        <el-divider direction="vertical" />太能尬電隊
                      </li>
                      <li>入圍獎
                        <el-divider direction="vertical" />湘橘餅干
                      </li>
                    </ul>
                  </el-col>
                </el-row>
              </el-collapse-item>
              <el-collapse-item
              title="大學概念組"
              name="3"
              >
                <el-row style="width:100%">
                  <el-col :span="12">
                    <ul class="final-li">
                      <li>&emsp;第一名
                        <el-divider direction="vertical" />7trade Technology
                      </li>
                      <li>&emsp;第二名
                        <el-divider direction="vertical" />漸行薦樂
                      </li>
                      <li>&emsp;第三名
                        <el-divider direction="vertical" />看病搜Easy
                      </li>
                      <li>&emsp;&emsp;佳作
                        <el-divider direction="vertical" />叭噗噗噗噗
                      </li>
                      <li>&emsp;&emsp;佳作
                        <el-divider direction="vertical" />軸承辨識
                      </li>
                    </ul>
                  </el-col>
                  <el-col :span="12">
                    <ul class="final-li">
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />橘貓聯盟
                      </li>
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />朝霧工作室
                      </li>
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />阿扁巴巴大俠
                      </li>
                      <li>入圍獎
                        <el-divider direction="vertical" />慢慢來
                      </li>
                      <li>入圍獎
                        <el-divider direction="vertical" />Real-現充人生
                      </li>
                    </ul>
                  </el-col>
                </el-row>
              </el-collapse-item>
            </el-collapse>
            <h2 class="el-icon-star-on">2021年第二屆得獎名單</h2>
            <el-collapse accordion>
              <el-collapse-item
              title="高中職實作組"
              name="1"
              >
                <el-row style="width:70%">
                  <el-col :span="12">
                    <ul class="final-li">
                      <li>&emsp;特優
                        <el-divider direction="vertical" />漫步彩虹
                      </li>
                      <li>&emsp;優等
                        <el-divider direction="vertical" />汪汪隊
                      </li>
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />AI疫把罩
                      </li>
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />no P K
                      </li>
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />漫步雲端
                      </li>
                    </ul>
                  </el-col>
                  <el-col :span="12">
                    <ul class="final-li">
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />漫漫星斗
                      </li>
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />鑫森淼焱壵
                      </li>
                      <li>入圍獎
                        <el-divider direction="vertical" />Plan A
                      </li>
                      <li>入圍獎
                        <el-divider direction="vertical" />漫步青雲
                      </li>
                      <li>入圍獎
                        <el-divider direction="vertical" />漫星雲彩
                      </li>
                    </ul>
                  </el-col>
                </el-row>
              </el-collapse-item>
              <el-collapse-item
              title="高中職概念組"
              name="2"
              >
                <el-row style="width:70%">
                  <el-col :span="12">
                    <ul class="final-li">
                      <li>&emsp;特優
                        <el-divider direction="vertical" />Pass kids
                      </li>
                      <li>&emsp;優等
                        <el-divider direction="vertical" />只想吃烤肉
                      </li>
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />車車我來了
                      </li>
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />新森南路
                      </li>
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />烏龜立大功
                      </li>
                    </ul>
                  </el-col>
                  <el-col :span="12">
                    <ul class="final-li">
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />動腦筋
                      </li>
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />聞雞魚
                      </li>
                      <li>入圍獎
                        <el-divider direction="vertical" />你說對不隊
                      </li>
                      <li>入圍獎
                        <el-divider direction="vertical" />今天吃什麼
                      </li>
                      <li>入圍獎
                        <el-divider direction="vertical" />翱翔天際
                      </li>
                    </ul>
                  </el-col>
                </el-row>
              </el-collapse-item>
            </el-collapse>
            <h2 class="el-icon-star-on">2020年第一屆得獎題目</h2>
            <el-collapse accordion>
              <el-collapse-item
              title="高中職實作組"
              name="1"
              >
                <el-row style="width:85%">
                  <el-col :span="24">
                    <ul class="final-li">
                      <li>&emsp;特優
                        <el-divider direction="vertical" />防疫戰士機器人
                      </li>
                      <li>&emsp;優等
                        <el-divider direction="vertical" />AIOT智能壓縮垃圾桶
                      </li>
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />商場虛擬機器人
                      </li>
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />LINE BEACON - 尋找東南亞移民的家鄉味
                      </li>
                    </ul>
                  </el-col>
                </el-row>
              </el-collapse-item>
              <el-collapse-item
              title="高中職概念組"
              name="2"
              >
                <el-row style="width:85%">
                  <el-col :span="24">
                    <ul class="final-li">
                      <li>&emsp;特優
                        <el-divider direction="vertical" />減重健康APP LW&H
                      </li>
                      <li>&emsp;優等
                        <el-divider direction="vertical" />與神同行 -鳳山 雙慈殿宗教意象行銷 策略
                      </li>
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />菱殼炭
                      </li>
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />失塑列車
                      </li>
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />LINE Eats 健康管理聊天機器人
                      </li>
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />行銷賣場-全聯福利中心
                      </li>
                      <li>&emsp;佳作
                        <el-divider direction="vertical" />改善舊衣回收-創造 JOY永續新生活 以智能設備改善傳統舊衣回收之問題
                      </li>
                    </ul>
                  </el-col>
                </el-row>
              </el-collapse-item>
            </el-collapse>
          </el-container>
        </div>
      </transition>
    </section>
    <section
      class="section8"
      id="Activities"
    >
      <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeInRight"
        leave-active-class="animate__animated animate__fadeOutLeft"
        mode="out-in"
      >
        <div
          class="main_title_container"
          v-show="S8t"
          style="width: 60vw"
        >
          <div class="main_title">活動花絮</div>
          <el-divider style="background-color: #ffffff"></el-divider>
          <el-container class="RuleContentContainer">
            <h2 class="el-icon-camera">2023年第四屆活動花絮</h2>
            <el-collapse accordion>
              <el-collapse-item
              title="人氣獎&大合照"
              name="1"
              >
              <div>
                <template>
                  <el-carousel :interval="3000">
                    <el-carousel-item v-for="(img,index) in Group4rdImgList" :key="index">
                      <img class="CarouselImg" v-bind:src="img.url" loading=”lazy”>
                    </el-carousel-item>
                  </el-carousel>
                </template>
              </div>
              </el-collapse-item>
              <el-collapse-item
              title="高中職實作組頒獎合影"
              name="2"
              >
              <div>
                <template>
                  <el-carousel :interval="3000">
                    <el-carousel-item v-for="(img,index) in Implement4rdAwardImgList" :key="index">
                      <img class="CarouselImg" v-bind:src="img.url" loading=”lazy”>
                    </el-carousel-item>
                  </el-carousel>
                </template>
              </div>
              </el-collapse-item>
              <el-collapse-item
              title="高中職概念組頒獎合影"
              name="3"
              >
              <div>
                <template>
                  <el-carousel :interval="3000">
                    <el-carousel-item v-for="(img,index) in Concept4rdAwardImgList" :key="index">
                      <img class="CarouselImg" v-bind:src="img.url" loading=”lazy”>
                    </el-carousel-item>
                  </el-carousel>
                </template>
              </div>
              </el-collapse-item>
              <el-collapse-item
              title="大學概念組頒獎合影"
              name="4"
              >
              <div>
                <template>
                  <el-carousel :interval="3000">
                    <el-carousel-item v-for="(img,index) in ConceptU4rdAwardImgList" :key="index">
                      <img class="CarouselImg" v-bind:src="img.url" loading=”lazy”>
                    </el-carousel-item>
                  </el-carousel>
                </template>
              </div>
              </el-collapse-item>
            </el-collapse>
            <h2 class="el-icon-camera">2022年第三屆活動花絮</h2>
            <el-collapse accordion>
              <el-collapse-item
              title="大合照"
              name="1"
              >
              <div>
                <template>
                  <el-carousel :interval="3000">
                    <el-carousel-item v-for="(img,index) in Group3rdImgList" :key="index">
                      <img class="CarouselImg" v-bind:src="img.url" loading=”lazy”>
                    </el-carousel-item>
                  </el-carousel>
                </template>
              </div>
              </el-collapse-item>
              <el-collapse-item
              title="高中職實作組頒獎合影"
              name="2"
              >
              <div>
                <template>
                  <el-carousel :interval="3000">
                    <el-carousel-item v-for="(img,index) in Implement3rdAwardImgList" :key="index">
                      <img class="CarouselImg" v-bind:src="img.url" loading=”lazy”>
                    </el-carousel-item>
                  </el-carousel>
                </template>
              </div>
              </el-collapse-item>
              <el-collapse-item
              title="高中職概念組頒獎合影"
              name="3"
              >
              <div>
                <template>
                  <el-carousel :interval="3000">
                    <el-carousel-item v-for="(img,index) in Concept3rdAwardImgList" :key="index">
                      <img class="CarouselImg" v-bind:src="img.url" loading=”lazy”>
                    </el-carousel-item>
                  </el-carousel>
                </template>
              </div>
              </el-collapse-item>
              <el-collapse-item
              title="大學概念組頒獎合影"
              name="4"
              >
              <div>
                <template>
                  <el-carousel :interval="3000">
                    <el-carousel-item v-for="(img,index) in ConceptU3rdAwardImgList" :key="index">
                      <img class="CarouselImg" v-bind:src="img.url" loading=”lazy”>
                    </el-carousel-item>
                  </el-carousel>
                </template>
              </div>
              </el-collapse-item>
              <el-collapse-item
              title="高中職實作組"
              name="5"
              >
              <div>
                <template>
                  <el-carousel :interval="3000" indicator-position="none">
                    <el-carousel-item v-for="(img,index) in Implement3rdImgList" :key="index">
                      <img class="CarouselImg" v-bind:src="img.url" loading=”lazy”>
                    </el-carousel-item>
                  </el-carousel>
                </template>
              </div>
              </el-collapse-item>
              <el-collapse-item
              title="高中職概念組"
              name="6"
              >
              <div>
                <template>
                  <el-carousel :interval="3000" indicator-position="none">
                    <el-carousel-item v-for="(img,index) in Concept3rdImgList" :key="index">
                      <img class="CarouselImg" v-bind:src="img.url" loading=”lazy”>
                    </el-carousel-item>
                  </el-carousel>
                </template>
              </div>
              </el-collapse-item>
              <el-collapse-item
              title="大學概念組"
              name="7"
              >
              <div>
                <template>
                  <el-carousel :interval="3000" indicator-position="none">
                    <el-carousel-item v-for="(img,index) in ConceptU3rdImgList" :key="index">
                      <img class="CarouselImg" v-bind:src="img.url" loading=”lazy”>
                    </el-carousel-item>
                  </el-carousel>
                </template>
              </div>
              </el-collapse-item>
            </el-collapse>
            <h2 class="el-icon-camera">2021年第二屆活動花絮</h2>
            <el-collapse accordion>
              <el-collapse-item
              title="活動影片"
              name="1"
              >
              <div>
              <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/6XWbaFmu_eo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              </el-collapse-item>
              <el-collapse-item
              title="大合照"
              name="2"
              >
              <div>
                <template>
                  <el-carousel :interval="3000">
                    <el-carousel-item v-for="(img,index) in GroupImgList" :key="index">
                      <img class="CarouselImg" v-bind:src="img.url" loading=”lazy”>
                    </el-carousel-item>
                  </el-carousel>
                </template>
              </div>
              </el-collapse-item>
              <el-collapse-item
              title="高中職實作組頒獎合影"
              name="3"
              >
              <div>
                <template>
                  <el-carousel :interval="3000">
                    <el-carousel-item v-for="(img,index) in ImplementAwardImgList" :key="index">
                      <img class="CarouselImg" v-bind:src="img.url" loading=”lazy”>
                    </el-carousel-item>
                  </el-carousel>
                </template>
              </div>
              </el-collapse-item>
              <el-collapse-item
              title="高中職概念組頒獎合影"
              name="4"
              >
              <div>
                <template>
                  <el-carousel :interval="3000">
                    <el-carousel-item v-for="(img,index) in ConceptAwardImgList" :key="index">
                      <img class="CarouselImg" v-bind:src="img.url" loading=”lazy”>
                    </el-carousel-item>
                  </el-carousel>
                </template>
              </div>
              </el-collapse-item>
            </el-collapse>
          </el-container>
        </div>
      </transition>
    </section>
    <section
      class="section9"
      id="Faq"
    >
      <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__fadeInRight"
        leave-active-class="animate__animated animate__fadeOutLeft"
        mode="out-in"
      >
        <div
          class="main_title_container"
          v-show="S9t"
        >
          <div class="main_title">FAQ</div>
          <el-divider style="background-color: #ffffff"></el-divider>
          <el-collapse
            accordion
            style="border: 0px; padding-bottom: 0px"
          >
            <el-collapse-item>
              <template slot="title"><i class="header-icon el-icon-info"></i>
                聯絡窗口
              </template>
              <div class="sub-item">
                <div>
                  <span>如有任何問題請先洽詢聯絡窗口或粉絲專頁私訊</span><br />
                  <span>國立高雄科技大學智慧商務系 杜助理<br />電子郵件：momoheui@gmail.com<br />聯絡電話：07-3814526
                    分機：17560<br /></span>
                  <span style="
                      color: linear-gradient(
                        to right,
                        orange,
                        yellow,
                        green,
                        cyan,
                        blue,
                        violet
                      );
                      width: 100%;
                    ">開發團隊/網頁維護：ITALAB</span>
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item>
              <template slot="title"><i class="header-icon el-icon-info"></i>
                簡章全文
              </template>
              <el-link
                type="primary"
                href="./cpt_guide.pdf"
                target="_blank"
              >點擊下載簡章全文</el-link><br />
            </el-collapse-item>
          </el-collapse>
        </div>
      </transition>
    </section>
  </div>
</template>

<script lang="js">
import PieChart from './PieChart.js'
export default {
  components: {
    PieChart
  },
  data () {
    return {
      waringdialogVisible: true,
      HomeBtn: false, // 回到首頁按鈕
      MenuIndex: '0', // 側邊懸浮選單高亮Index(滾動綁定)
      LoadingV: '', // 載入動畫，預先在這裡宣告，以供在不同的實例下取得這個參數
      MenuT: false, // 側邊懸浮選單進入動畫
      S1t: false, // 滾動進入動畫：section1(index 首頁)
      S2t: false, // 滾動進入動畫：section2(Info 關於競賽)
      S3t: false, // 滾動進入動畫：section3(News 最新消息)
      S4t: false, // 滾動進入動畫：section4(Schedule 競賽時程)
      S5t: false, // 滾動進入動畫：section5(Rule 競賽辦法)
      S6t: false, // 滾動進入動畫：section6(List 名單公布)
      S7t: false, // 滾動進入動畫：section7(Works 歷屆作品)
      S8t: false, // 滾動進入動畫：section8(活動花絮)
      S9t: false, // 滾動進入動畫：section9(Faq)
      RuleIndex: 0,
      RuleFirstTip: true,
      options: { // 圓餅圖選項
        responsive: true,
        plugins: { // chartjs-plugin-datalabels設定
          datalabels: {
            formatter: (value, ctx) => { // 圓餅圖上的標籤格式化
              const LEGEND_PERCENTAGE = value + '%' // 數值加'%'
              return LEGEND_PERCENTAGE
              // 預留程式碼區塊：可以計算所有數值轉換為百分比後的結果'
              // let sum = 0
              // const dataArr = ctx.chart.data.datasets[0].data
              // dataArr.map(data => {
              //   sum += data
              //   return 0
              // })
              // const percentage = (value * 100 / sum).toFixed(2) + '%'
            },
            color: 'black', // labels設定字體顏色
            align: 'start',
            textAlign: 'center', // labels設定對齊樣式
            font: {
              weight: 'bold',
              size: 18
            },
            offset: -40
          }
        }
      },
      Rules: [
        '參賽對象', '參賽規定', '組別說明', '【初賽】交件說明', '【初賽】評分項目', '【決賽】交件說明', '【決賽】競賽規定', '【決賽】評分項目', '獎勵辦法', '注意事項'
      ],
      ImplementAwardImgList: [
        { url: require('../assets/Implement_1.jpg') },
        { url: require('../assets/Implement_2.jpg') },
        { url: require('../assets/Implement_3.jpg') },
        { url: require('../assets/Implement_4.jpg') },
        { url: require('../assets/Implement_5.jpg') },
        { url: require('../assets/Implement_6.jpg') },
        { url: require('../assets/Implement_7.jpg') }
      ],
      ConceptAwardImgList: [
        { url: require('../assets/Concept_1.jpg') },
        { url: require('../assets/Concept_2.jpg') },
        { url: require('../assets/Concept_3.jpg') },
        { url: require('../assets/Concept_4.jpg') },
        { url: require('../assets/Concept_5.jpg') },
        { url: require('../assets/Concept_6.jpg') },
        { url: require('../assets/Concept_7.jpg') }
      ],
      GroupImgList: [
        { url: require('../assets/Group_1.jpg') },
        { url: require('../assets/Group_2.jpg') },
        { url: require('../assets/Group_3.jpg') }
      ],
      Implement3rdAwardImgList: [
        { url: require('../assets/Implement_3rd_22.jpg') },
        { url: require('../assets/Implement_3rd_23.jpg') },
        { url: require('../assets/Implement_3rd_24.jpg') },
        { url: require('../assets/Implement_3rd_25.jpg') },
        { url: require('../assets/Implement_3rd_26.jpg') },
        { url: require('../assets/Implement_3rd_27.jpg') },
        { url: require('../assets/Implement_3rd_28.jpg') },
        { url: require('../assets/Implement_3rd_29.jpg') }
      ],
      Concept3rdAwardImgList: [
        { url: require('../assets/Concept_3rd_22.jpg') },
        { url: require('../assets/Concept_3rd_23.jpg') },
        { url: require('../assets/Concept_3rd_24.jpg') },
        { url: require('../assets/Concept_3rd_25.jpg') },
        { url: require('../assets/Concept_3rd_26.jpg') },
        { url: require('../assets/Concept_3rd_27.jpg') },
        { url: require('../assets/Concept_3rd_28.jpg') },
        { url: require('../assets/Concept_3rd_29.jpg') }
      ],
      ConceptU3rdAwardImgList: [
        { url: require('../assets/ConceptU_3rd_14.jpg') },
        { url: require('../assets/ConceptU_3rd_15.jpg') },
        { url: require('../assets/ConceptU_3rd_16.jpg') },
        { url: require('../assets/ConceptU_3rd_17.jpg') },
        { url: require('../assets/ConceptU_3rd_18.jpg') },
        { url: require('../assets/ConceptU_3rd_19.jpg') },
        { url: require('../assets/ConceptU_3rd_20.jpg') },
        { url: require('../assets/ConceptU_3rd_21.jpg') }
      ],
      Group3rdImgList: [
        { url: require('../assets/Group_3rd_1.jpg') },
        { url: require('../assets/Group_3rd_2.jpg') },
        { url: require('../assets/Group_3rd_3.jpg') }
      ],
      Implement3rdImgList: [
        { url: require('../assets/Implement_3rd_1.jpg') },
        { url: require('../assets/Implement_3rd_2.jpg') },
        { url: require('../assets/Implement_3rd_3.jpg') },
        { url: require('../assets/Implement_3rd_4.jpg') },
        { url: require('../assets/Implement_3rd_5.jpg') },
        { url: require('../assets/Implement_3rd_6.jpg') },
        { url: require('../assets/Implement_3rd_7.jpg') },
        { url: require('../assets/Implement_3rd_8.jpg') },
        { url: require('../assets/Implement_3rd_9.jpg') },
        { url: require('../assets/Implement_3rd_10.jpg') },
        { url: require('../assets/Implement_3rd_11.jpg') },
        { url: require('../assets/Implement_3rd_12.jpg') },
        { url: require('../assets/Implement_3rd_13.jpg') },
        { url: require('../assets/Implement_3rd_14.jpg') },
        { url: require('../assets/Implement_3rd_15.jpg') },
        { url: require('../assets/Implement_3rd_16.jpg') },
        { url: require('../assets/Implement_3rd_17.jpg') },
        { url: require('../assets/Implement_3rd_18.jpg') },
        { url: require('../assets/Implement_3rd_19.jpg') },
        { url: require('../assets/Implement_3rd_20.jpg') },
        { url: require('../assets/Implement_3rd_21.jpg') }
      ],
      Concept3rdImgList: [
        { url: require('../assets/Concept_3rd_1.jpg') },
        { url: require('../assets/Concept_3rd_2.jpg') },
        { url: require('../assets/Concept_3rd_3.jpg') },
        { url: require('../assets/Concept_3rd_4.jpg') },
        { url: require('../assets/Concept_3rd_5.jpg') },
        { url: require('../assets/Concept_3rd_6.jpg') },
        { url: require('../assets/Concept_3rd_7.jpg') },
        { url: require('../assets/Concept_3rd_8.jpg') },
        { url: require('../assets/Concept_3rd_9.jpg') },
        { url: require('../assets/Concept_3rd_10.jpg') },
        { url: require('../assets/Concept_3rd_11.jpg') },
        { url: require('../assets/Concept_3rd_12.jpg') },
        { url: require('../assets/Concept_3rd_13.jpg') },
        { url: require('../assets/Concept_3rd_14.jpg') },
        { url: require('../assets/Concept_3rd_15.jpg') },
        { url: require('../assets/Concept_3rd_16.jpg') },
        { url: require('../assets/Concept_3rd_17.jpg') },
        { url: require('../assets/Concept_3rd_18.jpg') },
        { url: require('../assets/Concept_3rd_19.jpg') },
        { url: require('../assets/Concept_3rd_20.jpg') },
        { url: require('../assets/Concept_3rd_21.jpg') }
      ],
      ConceptU3rdImgList: [
        { url: require('../assets/ConceptU_3rd_1.jpg') },
        { url: require('../assets/ConceptU_3rd_2.jpg') },
        { url: require('../assets/ConceptU_3rd_3.jpg') },
        { url: require('../assets/ConceptU_3rd_4.jpg') },
        { url: require('../assets/ConceptU_3rd_5.jpg') },
        { url: require('../assets/ConceptU_3rd_6.jpg') },
        { url: require('../assets/ConceptU_3rd_7.jpg') },
        { url: require('../assets/ConceptU_3rd_8.jpg') },
        { url: require('../assets/ConceptU_3rd_9.jpg') },
        { url: require('../assets/ConceptU_3rd_10.jpg') },
        { url: require('../assets/ConceptU_3rd_11.jpg') },
        { url: require('../assets/ConceptU_3rd_12.jpg') },
        { url: require('../assets/ConceptU_3rd_13.jpg') }
      ],
      Group4rdImgList: [
        { url: require('../assets/Group_4th_1.jpg') },
        { url: require('../assets/Group_4th_2.jpg') },
        { url: require('../assets/Group_4th_3.jpg') }
      ],
      Implement4rdAwardImgList: [
        { url: require('../assets/Implement_4th_1.jpg') },
        { url: require('../assets/Implement_4th_2.jpg') },
        { url: require('../assets/Implement_4th_3.jpg') },
        { url: require('../assets/Implement_4th_4.jpg') },
        { url: require('../assets/Implement_4th_5.jpg') },
        { url: require('../assets/Implement_4th_6.jpg') },
        { url: require('../assets/Implement_4th_7.jpg') },
        { url: require('../assets/Implement_4th_8.jpg') }
      ],
      Concept4rdAwardImgList: [
        { url: require('../assets/Concept_4th_1.jpg') },
        { url: require('../assets/Concept_4th_2.jpg') },
        { url: require('../assets/Concept_4th_3.jpg') },
        { url: require('../assets/Concept_4th_4.jpg') },
        { url: require('../assets/Concept_4th_5.jpg') },
        { url: require('../assets/Concept_4th_6.jpg') },
        { url: require('../assets/Concept_4th_7.jpg') },
        { url: require('../assets/Concept_4th_8.jpg') }
      ],
      ConceptU4rdAwardImgList: [
        { url: require('../assets/ConceptU_4th_1.jpg') },
        { url: require('../assets/ConceptU_4th_2.jpg') },
        { url: require('../assets/ConceptU_4th_3.jpg') },
        { url: require('../assets/ConceptU_4th_4.jpg') },
        { url: require('../assets/ConceptU_4th_5.jpg') },
        { url: require('../assets/ConceptU_4th_6.jpg') },
        { url: require('../assets/ConceptU_4th_7.jpg') },
        { url: require('../assets/ConceptU_4th_8.jpg') }
      ]
    }
  },
  metaInfo: {
    title: '2023高科盃全國商業智慧競賽',
    meta: [
      {
        charset: 'utf-8'
      },
      {
        name: 'keywords', content: '高科盃 全國商業智慧競賽 總獎金63000元整 國立高科大 國立高雄科技大學 智慧商務系 nkust ic'
      },
      {
        name: 'description', content: '為引發高中職及大學部學生對於「智慧商務」之創意構想，培養跨領域之專業人才，鼓勵學生積極嘗試與發想，提高創新思考能力，並提昇知識整合、實作及人際溝通合作之能力。國立高雄科技大學商業智慧學院暨智慧商務系舉辦「第四屆高科盃全國商業智慧競賽」，期盼能激發學生創意及興趣及增加未來在職場創就業之競爭力，並提供全國各校學生相互交流的機會。'
      }
    ]
  },
  methods: {
    Scroll (section) {
      // 由側邊懸浮選單觸發，傳入section的id，以此宣告常數EL
      // 並呼叫document的method:scrollIntoView捲動卷軸直到該元素可見。
      const EL = document.getElementById(section)
      if (EL) {
        EL.scrollIntoView({
          behavior: 'smooth'
        })
      }
    }
  },
  mounted () {
    const SELF = this
    // 這裡的this是vue
    // 因為callback中的這個繫結沒有指向vue實例。
    // 為解決這個問題，在建立的hook中定義一個變數var SELF = this，指向vue實例，在callback中使用SELF引用資料屬性。
    window.addEventListener('scroll', function () {
      SELF.s2t = true
      if (window.scrollY > 800) {
        SELF.HomeBtn = true
      } else {
        SELF.HomeBtn = false
      }
      // 滾動監聽，這裡處理回到頂端，當滾動量大於800時讓HomeBtn出現
      SELF.MenuIndex = '' + Math.round(window.scrollY / parseInt(document.body.clientHeight / 9))// parseInt(document.body.clientHeight / window.scrollY)
      // 滾動監聽，這裡處理側邊懸浮選單的高亮Index，計算現在滾到哪一個section，綁定到MenuIndex。
      switch (Math.round(window.scrollY / parseInt(document.body.clientHeight / 9))) {
        case (0): {
          SELF.S2t = true
          SELF.$router.push('').catch(() => {})
          break
        }
        case (1): {
          SELF.S2t = true
          SELF.$router.push('#Info').catch(() => {})
          break
        }
        case (2): {
          SELF.S3t = true
          SELF.$router.push('#News').catch(() => {})
          break
        }
        case (3): {
          SELF.S4t = true
          SELF.$router.push('#Schedule').catch(() => {})
          break
        }
        case (4): {
          SELF.S5t = true
          if (SELF.RuleFirstTip) {
            SELF.$message.success('點擊左邊的項目來查看詳細內容！')
            SELF.RuleFirstTip = false
          }
          SELF.$router.push('#Rules').catch(() => {})
          break
        }
        case (5): {
          SELF.S6t = true
          SELF.$router.push('#List').catch(() => {})
          break
        }
        case (6): {
          SELF.S7t = true
          SELF.$router.push('#Works').catch(() => {})
          break
        }
        case (7): {
          SELF.S8t = true
          SELF.$router.push('#Activites').catch(() => {})
          break
        }
        case (8): {
          SELF.S9t = true
          SELF.$router.push('#Faq').catch(() => {})
          break
        }
        // 滾動監聽，這裡處理進入動畫和網址的更新，計算現在滾到哪一個section，逐一讓一個個section出現，同時推入section的id到網址。
      }
    })
    window.addEventListener('load', function (event) {
      SELF.LoadingV.close()
      setTimeout(() => {
        SELF.MenuT = true
        SELF.S1t = true
      }, 500)
    })
    // 頁面載入監聽，除了使全屏載入畫面關閉，也呼叫位在第一個Section的動畫出現。
  },
  created () {
    this.LoadingV = this.$loading({
      lock: true,
      text: '載入中...',
      spinner: 'el-icon-loading',
      background: 'rgb(255, 255, 255)'
    })
    // 在Vue生命週期:created階段建立一個element ui Loading實例，並在Mounted階段將關閉的呼叫加入頁面載入完成的監聽器。
  }
}
</script>

<style scoped>

.el-menu {
  box-shadow: 2px 2px 5px rgb(50, 50, 50);
  border-radius: 5px;
}

.el-menu-item {
  text-align: left;
  height: 55px;
}

.el-top-item {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.el-bottom-item {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.final-li {
  list-style: none;
}
.final-li > li {
  padding-left: 0rem;
  text-indent: -0.7rem;
}
.final-li > li::before {
  content: "🏅 ";
}
.section1 {
  background-image: url("../assets/section1_background.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.section2 {
  background-image: url("../assets/section2_background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.section3 {
  background-image: url("../assets/section3_background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.section4 {
  background-image: url("../assets/section4_background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.section5 {
  background-image: url("../assets/section5_background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.section6 {
  background-image: url("../assets/section6_background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.section7 {
  background-image: url("../assets/section7_background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.section8 {
  background-image: url("../assets/section8_background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.section9 {
  background-image: url("../assets/section9_background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.main_title_container {
  border-radius:15px;
  text-align: left;
  padding: 50px;
  background: linear-gradient(
    to bottom,
    rgba(30, 87, 153, 0.8) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  color: rgb(255, 255, 255);
  width: 50vw;
}

.main_title {
  position: relative;
  z-index: 100;
  font-size: 10vmin;
  font-weight: 900;
  text-align: left;
}

.CarouselImg{
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

section {
  height: 100vh;
  display: block;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background: #a6a6a6;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
  border-radius: 10px;
}

.section1,
.section2,
.section3,
.section4,
.section5,
.section6,
.section7,
.section8,
.section9{
  display: flex;
  justify-content: left;
  align-items: center;
}
.sub-item {
  font-size: 2vmin;
}
.main {
  font-family: Microsoft JhengHei;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
.RuleTitle {
  color: #303133;
  padding: 5px;
  border-radius: 5px;
  transition: all 500ms;
}
.RuleTitle:hover {
  background-color: rgba(0, 0, 0, 0.1);
  transition: background-color 500ms;
}
.RuleTitleContainer {
  text-align: left;
  font-size: 2.5vmin;
  font-weight: 900;
  height: 60vh;
  background-color: white;
  padding: 15px;
  border-radius: 5px;
  overflow-y: scroll;
}
.RuleContentContainer {
  text-align: left;
  font-size: 2vmin;
  height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
  padding: 15px;
  border-radius: 5px;
  color: #303133;
}
.RuleContentTitle {
  text-align: left;
  font-size: 3vmin;
  font-weight: 900;
  color: #303133;
}
.RuleContent {
  text-align: left;
  font-size: 2vmin;
  color: #303133;
}
.RuleIndexActive {
  color: #409eff;
}
@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}
.float {
  animation: float 6s ease-in-out infinite;
}
.el-collapse >>> .el-collapse-item__header {
  padding-left: 15px;
}
.el-collapse >>> .el-collapse-item__content {
  padding-left: 15px;
}
</style>
